import {
  accessIcon,
  chapterIcon,
  classIcon,
  dashboardIcon,
  levelIcon,
  logoutIcon,
  newUserIcon,
  printIcon,
  questionIcon,
  reportIcon,
  rolesIcon,
  schoolAdminIcon,
  schoolIcon,
  sectionIcon,
  sessionIcon,
  skillIcon,
  studentIcon,
  subjectIcon,
  superAdminIcon,
  teacherIcon,
  topicIcon,
  userIcon,
  questionPaperIcon,
  examIcon,
  profileIcon,
  instructionIcon,
  teacherReportIcon,
  schoolReportIcon,
  studentReportIcon,
} from "assets";
import { RouteType } from "./config";

// Super Admin Routes here //
const superAdminRoutes = [
  {
    path: "/super-admin/dashboard",
    state: "dashbord",
    sidebarProps: {
      displayText: "Dashboard",
      img: <img src={dashboardIcon} alt="Levels" />,
    },
    children: null,
  },
  {
    path: "/super-admin/sessions",
    state: "session",
    sidebarProps: {
      displayText: "Session",
      img: <img src={sessionIcon} alt="Levels" />,
    },
    children: null,
  },
  {
    path: "/super-admin/schools",
    state: "client",
    sidebarProps: {
      displayText: "Schools",
      img: <img src={schoolIcon} alt="Levels" />,
    },
    children: null,
  },
  {
    path: "/super-admin/school-admins",
    state: "school",
    sidebarProps: {
      displayText: "School Admins",
      img: <img src={schoolAdminIcon} alt="Levels" />,
    },
    children: null,
  },
  {
    path: "/super-admin/roles",
    state: "role",
    sidebarProps: {
      displayText: "Roles",
      img: <img src={rolesIcon} alt="Levels" />,
    },
    children: null,
  },
  {
    path: "/super-admin/module-wise-access",
    state: "permission",
    sidebarProps: {
      displayText: "Module Wise Access",
      img: <img src={accessIcon} alt="Topics" />,
    },
    children: null,
  },
  {
    path: "/super-admin/view-reports",
    state: "report",
    sidebarProps: {
      displayText: "View Reports",
      img: <img src={reportIcon} alt="accordionIcon" />,
    },
    children: [
      {
        path: "/super-admin/view-reports/school-reports",
        state: "report",
        sidebarProps: {
          displayText: "School Report",
          img: <img src={schoolReportIcon} alt="schoolReportIcon" />,
        },
        children: null,
      },
      {
        path: "/super-admin/view-reports/teacher-report",
        state: "report",
        sidebarProps: {
          displayText: "Teacher Report",
          img: <img src={teacherReportIcon} alt="teacherReportIcon" />,
        },
        children: null,
      },
      {
        path: "/super-admin/view-reports/student-reports",
        state: "report",
        sidebarProps: {
          displayText: "Student Report",
          img: <img src={studentReportIcon} alt="studentReportIcon" />,
        },
        children: null,
      },
    ],
  },
];

// School Admin Routes here //

const schoolAdminRoutes = [
  {
    path: "/school-admin/dashboard",
    state: "dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      img: <img src={dashboardIcon} alt="Levels" />,
    },
    children: null,
  },
  {
    path: "/school-admin/classes",
    state: "grade",
    sidebarProps: {
      displayText: "Classes",
      img: <img src={classIcon} alt="Levels" />,
    },
    children: null,
  },
  {
    path: "/school-admin/sections",
    state: "section",
    sidebarProps: {
      displayText: "Sections",
      img: <img src={sectionIcon} alt="Levels" />,
    },
    children: null,
  },
  {
    path: "/school-admin/subjects",
    state: "subject",
    sidebarProps: {
      displayText: "Subjects",
      img: <img src={subjectIcon} alt="Levels" />,
    },
    children: null,
  },
  {
    path: "/school-admin/chapters",
    state: "chapter",
    sidebarProps: {
      displayText: "Chapters",
      img: <img src={chapterIcon} alt="Levels" />,
    },
    children: null,
  },
  {
    path: "/school-admin/topics",
    state: "subject-topic",
    sidebarProps: {
      displayText: "Topics",
      img: <img src={topicIcon} alt="Topics" />,
    },
    children: null,
  },
  {
    path: "/school-admin/staffs",
    state: "staff",
    sidebarProps: {
      displayText: "Staffs",
      img: <img src={newUserIcon} alt="Skills" />,
    },
    children: null,
  },
  {
    path: "/school-admin/students",
    state: "student",
    sidebarProps: {
      displayText: "Students",
      img: <img src={studentIcon} alt="Skills" />,
    },
    children: null,
  },
  {
    path: "/school-admin/questions",
    state: "question",
    sidebarProps: {
      displayText: "Questions",
      img: <img src={questionIcon} alt="questionIcon" />,
    },
    children: null,
  },
  {
    path: "/school-admin/question-papers",
    state: "question-paper",
    sidebarProps: {
      displayText: "Question Papers",
      img: <img src={questionPaperIcon} alt="questionPaperIcon" />,
    },
    children: null,
  },
  {
    path: "/school-admin/exam",
    state: "exam",
    sidebarProps: {
      displayText: "Exam",
      img: <img src={examIcon} alt="examIcon" />,
    },
    children: null,
  },
  {
    path: "/school-admin/manage-profile",
    state: "profile",
    sidebarProps: {
      displayText: "Manage Profile",
      img: <img src={profileIcon} alt="profileIcon" />,
    },
    children: null,
  },
  {
    path: "/school-admin/manage-instruction",
    state: "instruction",
    sidebarProps: {
      displayText: "Manage Instruction",
      img: <img src={instructionIcon} alt="instructionIcon" />,
    },
    children: null,
  },
  {
    path: "/school-admin/module-wise-access",
    state: "permission",
    sidebarProps: {
      displayText: "Module Wise Access",
      img: <img src={accessIcon} alt="Topics" />,
    },
    children: null,
  },
];

// Teacher Admin Routes here //

const teacherAdminRoutes = [
  {
    path: "/teacher-admin/questions",
    state: "questions",
    sidebarProps: {
      displayText: "Questions",
      img: <img src={questionIcon} alt="questionIcon" />,
    },
    children: null,
  },
  {
    path: "/teacher-admin/question-papers",
    state: "question-papers",
    sidebarProps: {
      displayText: "Question Papers",
      img: <img src={questionIcon} alt="questionIcon" />,
    },
    children: null,
  },
  {
    path: "/teacher-admin/students",
    state: "students",
    sidebarProps: {
      displayText: "Students",
      img: <img src={studentIcon} alt="Skills" />,
    },
    children: null,
  },
  {
    path: "/teacher-admin/manage-instruction",
    state: "view-reports",
    sidebarProps: {
      displayText: "Manage Instruction",
      img: <img src={reportIcon} alt="questionIcon" />,
    },
    children: null,
  },
  {
    path: "/teacher-admin/exam",
    state: "exam",
    sidebarProps: {
      displayText: "Exam",
      img: <img src={reportIcon} alt="questionIcon" />,
    },
    children: null,
  },
  {
    path: "/teacher-admin/create-exams",
    state: "view-reports",
    sidebarProps: {
      displayText: "Exams",
      img: <img src={subjectIcon} alt="subjectIcon" />,
    },
    children: null,
  },
  {
    path: "/teacher-admin/manage-profile",
    state: "manage-profile",
    sidebarProps: {
      displayText: "Manage Profile",
      img: <img src={newUserIcon} alt="questionIcon" />,
    },
    children: null,
  },
];

const userRoutes: RouteType[] = [];

export { userRoutes, schoolAdminRoutes, superAdminRoutes, teacherAdminRoutes };
