import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { RootState } from "redux/store";
import { toastError } from "helpers/toastHelper";

// Define the state interface
interface DashboardData {
  // Define the structure of your dashboard data here
  // Example fields:
  totalQuestions: number;
  totalPapers: number;
  totalMarks: number;
  // Add other fields as necessary
}

interface DashboardState {
  data: DashboardData | null;
  isLoading: boolean;
  error: string | null;
}

// Initial state
const initialState: DashboardState = {
  data: null,
  isLoading: false,
  error: null,
};

// Async thunk for fetching dashboard data //
export const fetchDashboardDataAsync = createAsyncThunk(
  "dashboard/fetchData",
  async (_, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const dbToken = localStorage.getItem("client_db_token");
    const subDomain = localStorage.getItem("subdomain");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    const Token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/dashboard/all-counts`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token || SToken || Token}`,
            "db-token": dbToken || Sdb_token || db_token,
            "sub-domain": subDomain || "sub-domain4",
          },
        }
      );

      if (response?.data?.success) {
        return response?.data?.data;
      } else {
        toastError(response?.data?.message || "Error fetching dashboard data");
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Dashboard Slice here //
const dashboardSlice = createSlice({
  name: "dashboardState",
  initialState,
  reducers: {
    clearDashboardData: (state) => {
      state.data = null;
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardDataAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchDashboardDataAsync.fulfilled,
        (state, action: PayloadAction<DashboardData>) => {
          state.isLoading = false;
          state.data = action.payload;
        }
      )
      .addCase(
        fetchDashboardDataAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// selectors //
export const { clearDashboardData } = dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;
export const selectDashboardData = (state: RootState) =>
  state.dashboardState.data;
export const selectDashboardLoading = (state: RootState) =>
  state.dashboardState.isLoading;
export const selectDashboardError = (state: RootState) =>
  state.dashboardState.error;
